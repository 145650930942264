import { useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../utils/authContext";

const Navbar = () => {
    const { account, userData, login, logout } = useAuth(); // Get authentication details from the custom auth context

    const location = useLocation(); // Get current location to highlight active links    

    // Function to determine if the link is active based on the current path
    const isActiveLink = useCallback((path) => {
        const currentPath = location.pathname.endsWith('/') 
            ? location.pathname.slice(0, -1) // Remove trailing slash for consistency
            : location.pathname;
        return currentPath === path;
    }, [location.pathname]);

    // Filter out PDF Viewer, Login, and Logout items from the menu
    const filteredMenu = userData.user_menu?.filter(item => 
        item.path !== "/pdf-viewer/:dmpId" && 
        item.label.toLowerCase() !== "login" && 
        item.label.toLowerCase() !== "logout"
    );

    return (
        <nav className="bg-gray-800 px-4 text-white h-[50px] flex flex-wrap">
            <ul className="flex flex-wrap items-center h-full space-x-4">
                {/* Dynamically render the menu items from userData */}
                {filteredMenu?.map((item, index) => (
                    <li key={index} className="h-full group relative">
                        <Link 
                            to={item.path} 
                            className={`flex items-center h-full transition-all duration-500 ${isActiveLink(item.path) ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}
                        >
                            {item.label}
                        </Link>

                        {/* Render children if present */}
                        {/* {item.children && item.children.length > 0 && (
                            <ul className="absolute left-0 top-full w-[300px] hidden group-hover:block bg-white shadow-md border rounded">
                                {item.children.map((child, childIndex) => (
                                    <li key={childIndex} className="hover:bg-gray-100">
                                        <Link 
                                            to={child.path} 
                                            className="block px-4 py-2 text-sm text-gray-700 hover:text-primary-500"
                                        >
                                            {child.label}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        )} */}
                    </li>
                ))}

                {/* Authentication links: either login or logout based on the user's authentication status */}
                <li className="h-full">
                    {account ? (
                        <Link onClick={logout} className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Logout</Link>
                    ) : (
                        <Link onClick={login} className={`flex items-center h-full transition-all duration-500 ${isActiveLink('/') ? 'bg-primary-200 px-4' : 'hover:text-primary-100'}`}>Login</Link>
                    )}
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;