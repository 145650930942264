import React, { createContext, useContext, useEffect, useState, Suspense, useCallback } from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import useAuthKeyConfig from "./authConfig";
import { keyPart2 } from "./aesUtils";
import appInsightsService from "../services/appInsightsService";

// Create a Context for authentication data
const AuthContext = createContext();

// Get the default page from environment variable or use "/" as fallback
const DEFAULT_LOGIN_REDIRECT = process.env.REACT_APP_DEFAULT_PAGE_AFTER_LOGIN || "/";

// Custom hook to use the authentication context
export const useAuth = () => useContext(AuthContext);

// Export a constant for secure key handling
export const securedKey = "c9g24" + keyPart2;

export const AuthProvider = ({ children, defaultLoginRedirect=DEFAULT_LOGIN_REDIRECT }) => {
    // Destructure authentication keys and potential error from the hook
    const { clientIdKey, tenantIdKey, error: keyError } = useAuthKeyConfig();

    // State variables to manage authentication and MSAL instance
    const [account, setAccount] = useState(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [msalInstance, setMsalInstance] = useState(null);
    const [userData, setUserData] = useState([]); 
    const [defaultPage, setDefaultPage] = useState(defaultLoginRedirect);
    const [tokenExpiryDetails, setTokenExpiryDetails] = useState({
        isExpiring: false,
        expiryTime: null,
        timeRemaining: null
    });
    const navigate = useNavigate(null);
    const menuApiEndpoint = "/api/v1/user-menu/";

    // Initialize MSAL configuration and instance when clientIdKey is available
    useEffect(() => {
        if (clientIdKey) {
            const msalConfig = {
                auth: {
                    clientId: clientIdKey,
                    authority: `https://login.microsoftonline.com/${tenantIdKey}`,
                    redirectUri: `${window.location.origin}`,
                },
                cache: {
                    cacheLocation: "localStorage",
                    storeAuthStateInCookie: false,
                },
                scopes: ["openid", "profile", "user.read", `api://${clientIdKey}/Files.Read`],
            };

            const instance = new PublicClientApplication(msalConfig);
            setMsalInstance(instance);
        }
    }, [clientIdKey, tenantIdKey]);

    // Initialize MSAL and handle redirect response
    useEffect(() => {
        const initializeMsal = async () => {
            if (msalInstance) {
                try {
                    await msalInstance.initialize();
                    const response = await msalInstance.handleRedirectPromise();
                    if (response) {
                        console.log("MSAL Redirect Response:", response);
                        setAccount(response.account);
                    } else {
                        const currentAccounts = msalInstance.getAllAccounts();
                        if (currentAccounts.length === 1) {
                            setAccount(currentAccounts[0]);
                            
                            // Retrieve user data from localStorage if it exists
                            const storedUserData = localStorage.getItem('userData');
                            if (storedUserData) {
                                const parsedUserData = JSON.parse(storedUserData);
                                setUserData(parsedUserData);
                                setDefaultPage(parsedUserData.defaultPageUrl || DEFAULT_LOGIN_REDIRECT);
                            }
                        }
                    }
                } catch (error) {
                    console.error("Error handling redirect:", error);
                } finally {
                    setIsInitialized(true);
                    setIsLoading(false);
                }
            }
        };

        initializeMsal();
    }, [msalInstance]);

    useEffect(() => {
        if (account) {
            appInsightsService.initializeWithAccount({
                username: account.username,
                name: account.name
            });
        }
    }, [account]);

    // Function to fetch user data, including roles - NOW ONLY CALLED DURING LOGIN
    const fetchUserData = async (accessToken, userAccount) => {
        try {
            const userEmail = userAccount?.username || userAccount?.idTokenClaims?.email || userAccount?.idTokenClaims?.preferred_username;
            if (!userEmail) {
                console.error("User email not found in account object");
                return;
            }
            const response = await fetch(menuApiEndpoint, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json"
                }
            });
            const data = await response.json();
            if (data.status) {
                // Store user data in localStorage for persistent access
                localStorage.setItem('userData', JSON.stringify(data.payload));
                
                setUserData(data.payload);
                setDefaultPage(data.payload.defaultPageUrl || DEFAULT_LOGIN_REDIRECT);
            }
        } catch (error) {
            console.error("Failed to fetch user data:", error);
        }
    };

    // Login function using popup method
    const login = () => {
        if (msalInstance) {
            setIsLoading(true);
            msalInstance.loginPopup({
                scopes: msalInstance.getActiveAccount() ? msalInstance.getActiveAccount().scopes : [],
            }).then(async (response) => {
                setAccount(response.account);
                
                // IMPORTANT: Fetch user data ONLY during login
                await fetchUserData(response.accessToken, response.account);
                
                localStorage.setItem("msal.access.token", response.accessToken);
                console.log("Login successful");
                navigate(defaultPage);
            }).catch(error => {
                console.error("Login error:", error);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    };

    // Logout function using redirect method
    const logout = useCallback(() => {
        if (msalInstance) {
            setIsLoading(true);
            msalInstance.logoutRedirect({
                onRedirectNavigate: () => false,
            }).then(() => {
                setAccount(null);
                setUserData([]);
                localStorage.removeItem("msal.access.token");
                localStorage.removeItem("userData");
                setTokenExpiryDetails({
                    isExpiring: false,
                    expiryTime: null,
                    timeRemaining: null
                });
                navigate("/login");
                console.log("Logout successful");
            }).catch(error => {
                console.error("Logout error:", error);
            }).finally(() => {
                setTimeout(() => {
                    setIsLoading(false);
                }, 1500);
            });
        }
    }, [msalInstance, navigate]);

    // Handle potential errors from the key configuration hook
    if (keyError) {
        console.error("useAuthKeyConfig Error:", keyError);
        return <div>Error: {keyError}</div>;
    }

    // Rest of the code remains the same...
    return msalInstance ? (
        <MsalProvider instance={msalInstance}>
            <AuthContext.Provider value={{ account, login, logout, isInitialized, isLoading, setIsLoading, userData, defaultLoginRedirect }}>
                <Suspense fallback={<Loader />}>
                    {isLoading ? <Loader /> : children}
                    {tokenExpiryDetails.isExpiring && (
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-80 flex justify-center items-center z-50">
                            <div className="bg-[#f2f2f7] p-6 rounded-lg shadow-lg flex flex-wrap flex-col items-center">
                                <h2 className="text-xl text-primary-500 font-medium mb-4">Session Expiring Soon</h2>
                                <p className="font-semibold">Your session will expire in {Math.floor(tokenExpiryDetails.timeRemaining)} seconds.</p>
                                <p className="font-semibold mb-4">Renew your token to continue.</p>
                                {/* <div className="flex justify-between">
                                    <button onClick={renewToken} className="bg-primary-300 text-white h-10 px-10 py-2 rounded hover:bg-primary-500 transition-colors">Renew Token</button>
                                </div> */}
                            </div>
                        </div>
                    )}
                </Suspense>
            </AuthContext.Provider>
        </MsalProvider>
    ) : (
        <Loader /> // Show loader while initializing MSAL
    );
};