import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Initialize Sentry
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN, // Sentry DSN
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: process.env.REACT_APP_TRACE_SAMPLE_RATE, // Retrieve sample rate
    replaysSessionSampleRate: process.env.REACT_APP_REPLAY_SESSION_SAMPLE_RATE, // Retrieve session replay sample rate
    replaysOnErrorSampleRate: process.env.REACT_APP_REPLAY_ERROR_SAMPLE_RATE, // Retrieve error replay sample rate
    tracePropagationTargets: process.env.REACT_APP_TRACE_PROPAGATION_TARGETS, // Retrieve trace targets
});

const fetchConfig = async () => {
    try {
        const response = await fetch('/config.json');
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const config = await response.json();

        // Update document title
        document.title = config.title || 'DebtServe';

        // Update meta description
        let metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.content = config.description || 'Default description';
        } else {
            metaDescription = document.createElement('meta');
            metaDescription.name = 'description';
            metaDescription.content = config.description || 'Default description';
            document.head.appendChild(metaDescription);
        }

        return config;
    } catch (error) {
        Sentry.captureException(error);
        console.error('Error fetching config:', error);
        throw error; // Re-throw to ensure the rendering doesn't proceed
    }
};

// Ensure configuration is loaded before rendering the app
fetchConfig().then(() => {
    const root = ReactDOM.createRoot(document.getElementById('root'));
    root.render(
        <React.StrictMode>
            <Sentry.ErrorBoundary>
                <App />
            </Sentry.ErrorBoundary>
        </React.StrictMode>
    );
});

// Measure performance in the app
reportWebVitals();