import axios from 'axios';

// Base URL for API requests, fetched from environment variables
const apiUrl = `${window.location.origin}${process.env.REACT_APP_API_PATH_DIRECTORY}`;

// Create an Axios instance with default settings
const AxiosInstance = axios.create({
    baseURL: apiUrl, // Base URL for all requests
    headers: {
        'Content-Type': 'application/json', // Default content type for requests
    },
});

// Request interceptor to add authorization token
AxiosInstance.interceptors.request.use(
    (config) => {
        // Retrieve the access token from session storage
        const accessToken = localStorage.getItem('msal.access.token');

        // If an access token exists, add it to the Authorization header
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        }

        // Return the modified config object
        return config;
    },
    (error) => {
        // Handle request errors
        return Promise.reject(error);
    }
);

// Response interceptor to handle token expiration
AxiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.data?.status === false && 
            error.response?.data?.message?.includes("401 Client Error: Unauthorized")) {
            // Token is expired or invalid
            console.log('Token expired or invalid. Logging out...');
            
            // Redirect to logout page
            window.location.href = '/logout';
        }
        return Promise.reject(error);
    }
);

export const keyPart9 = "2c23";

export default AxiosInstance;